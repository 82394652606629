import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
const URL = environment.chat_url;
@Injectable({
  providedIn: 'root'
})

export class SocketService {

  // socket: Socket | null = null;
  constructor(
    private socket: Socket,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    // if(isPlatformBrowser(this.platformId)){
    //     this.socket = Socket;
    //   }
  } 

  connectOrGet(): any{
    // debugger;
    console.log('connectOrGet.......');
      if(this.socket !== null && this.socket instanceof Socket && this.socket.ioSocket.connected){
        console.log('connection DONE..........', this.socket);

        return this.socket;
      }else{
        console.log('connection check 2222', this.socket);
        
        const config: SocketIoConfig = { url: URL, options: {}};
        this.socket = new Socket(config);
        console.log('connection FAILD..........');
        
        return this.socket;
      } 
  }
  
}
