import { isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { SocketService } from './pages/chat/socket.service';
import { SocketService } from './shared/socket.service';

import { BehaviorService } from './shared/behavior.service';
// import { SocketService } from './socket.service';
// import { Socket } from 'ngx-socket-io';

// const URL = "http://74.208.206.18:4333";
// const URL = "http://198.251.65.146:4333/";
const URL = environment.chat_url; 
const BASEURL = environment.url;
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  socket: any = null;
  // constructor(private http: HttpClient,private socket: Socket,@Inject(PLATFORM_ID) private platformId: Object) {
  // if(isPlatformBrowser(this.platformId)){
  //   this.socket = this.socketService.connectOrGet();
  // }
  // }
  constructor(
    private http: HttpClient,
    private socketService: SocketService,
    private _bs:BehaviorService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
    this.socket = this.socketService.connectOrGet();
    //('socket', this.socket);
    }
  }

  public sendMessage(message:any) {
    console.log(message, '=======message from socket');
    // console.log(this.platformId, '=======this.platformId from socket');

    // console.log(isPlatformBrowser(this.platformId), '=======isPlatformBrowser(this.platformId) from socket');
    
    if (isPlatformBrowser(this.platformId)) {      
      this.socket.emit('send-message', message);
      console.log(message, '=========message Sent');
    }
  }
  // public connectUserOnline(message) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.socket.emit('connection', message);
  //   }
  // }
  public fetchHistory(data:any) {
    // if (isPlatformBrowser(this.platformId)) {
    this.socket.emit('fetch-history', data);
    // //('fetch-history', data);

    // }
  }
  public deleteMessage(data:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('delete-message', data);
      console.log(data,"DELETED")
    }
  }

  public deleteforEveryoneMessage(data:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('delete-message', data);
    }
  }


  public updateDeleteMessage = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('delete-message', (message:any) => {
          observer.next(message);
          // console.log(message);
          
        });
      });
    }
  };

  public updateHistory = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('update-history', (message:any) => {
          observer.next(message);
        });
      });
    }
  };

  public user_history = (from: string, to: string) => {
    //('user_history');   
    return this.http.get(URL + "chat/user_history?from=" + from + "&to=" + to);
  };

  public removeConnection(data:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('remove-connection', data);
      console.log('removeConnection.......');
    }
  }

  sendNotification(context: any) {
    return this.http.post(BASEURL + `messages`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.errorMgmt)
    );

  }

  public updateConnection = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('update-connection', (data:any) => {
          observer.next(data);
          console.log('updateConnection.......');
        });
      });
    }
  };


  public check = () => {
    // if (isPlatformBrowser(this.platformId)) {
    return Observable.create((observer:any) => {
      this.socket.on('check-status', (data:any) => {
        observer.next(data);
      });
    });
    // }
  };

  public getMessages = () => {
    return Observable.create((observer:any) => {
      this.socket.on(`new-message`, (message:any) => {
        // //('new-message', message);
        observer.next(message);
      });
    });
  };

  public sendOne2OneMessage(message:any) {
    this.socket.emit('one-to-one', message);

  }
  public joinGoup(data:any) {
    this.socket.emit('join-room', data);

  }
  public getjoingroup = () => {
    return Observable.create((observer:any) => {
      this.socket.on(`join-room`, (message:any) => {
        // console.log('room joined', message);
        observer.next(message);
      });
    });
  };
  // public joinGoup(message:any) {
  // let socket =  this.socket
  //  socket.on('join-room', function(data) {

  //     // Json Parse String To Access Child Elements
  //     let messageJson = JSON.parse(data)
  //     let room1 = messageJson.room1
  
  //     console.log('======Joined Room========== ')
  //     console.log(room1)
  
  //     socket.join(room1, function(err) {
  //     })
  // })

  // }
 


//   public joinGoup = () => {
//  let   socket = this.socket
//    socket.on('connect',function(room){  
//       try{
//         console.log('[socket]','join-room :',room)
//      socket.join(room);
//         socket.to(room).emit('join-room', socket.id);
//       }catch(e){
//         console.log('[error]','join room :',e);
//     socket.emit('error','couldnt perform requested action');
//       }
//     })
//   };

  public testMessages = () => {
    return Observable.create((observer:any) => {
      this.socket.on('receive-message', (message:any) => {
        observer.next(message);
        console.log(message, '====== message recived');
      });
    });

  };

  //read msg
  // public readMessage(data:any) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.socket.emit('read-message', data);
  //     console.log(data);
      
  //   }
  //   // this._bs.setDirestion('reloadAPi')
  // }





  public getNotifs = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('notify-message', (data:any) => {
          observer.next(data);
        });
      });
    }
  };

  public getUnreadNotifs = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('un-noti', (data:any) => {
          observer.next(data);
        });
      });
    }
  };

  public sendNotif(data:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('notify-message', data);
    }
  }
  public unreadNotif(data:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('un-noti', data);
    }
  }
  public readMessageStatus(data:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('read-message', data);
    }
  }


  // read msg
  public verifyReadStatus = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('read-message', (data:any) => {
          observer.next(data);
        });
      });
    }
  };
  public showUserOnline = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('user-online', (data:any) => {
          observer.next(data);
        });
      });
    }
  };
  public showUserOffline = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('user-offline', (data:any) => {
          observer.next(data);
        });
      });
    }
  };
  

  disconnectSocket = (id) => {
    console.log('disconnect otr')
    if (isPlatformBrowser(id)) {
      return Observable.create((observer:any) => {
        this.socket.emit('disconnect', (message:any) => {
          observer.next(message);
          console.log('disconnect', message)
        });
      });
    }
  };

  // public disconnectSocket = () => {
  //   if (isPlatformBrowser(this.platformId)) {
  //     console.log('data');
      
  //   return Observable.create((observer:any) => {
  //   //('connect service', observer);
  //     this.socket.on('disconnect', (message:any) => {
  //     console.log("disconnect")
  //       observer.next(message);
  //       //('connect service', message);
  //     });
  //   });
  // }
  // };

  // disconnectSocket(id:any){
  //   this.socket.disconnect(id)
  //   console.log('disconnectedddddddddddd');    
  // }

  connectSocket(id:any){
    this.socket.connect(id)
    console.log('connect..............');
    
  }


  public unreadMessagesCount(data) {
    // console.log(data,"unread message event")
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit("unread-count", data);
      // console.log('data',data);
      
    }
  }
  public getunreadcount = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer) => {
        this.socket.on("unread-count", (data) => {
          observer.next(data);
        });
      });
    }
  };
  public connect = () => {
    console.log("connectedddddddddddddd111111111111111")
    // if (isPlatformBrowser(this.platformId)) {
    return Observable.create((observer:any) => {
      //('connect service', observer);
      this.socket.on('connect', (message:any) => {
        observer.next(message);
        console.log("connectedddddddddddddd")
        //('connect service', message);
      });
    });
    // }
  };

  public addUser(newUser:any) {
    // if (isPlatformBrowser(this.platformId)) {
    this.socket.emit('new-user', newUser);
    //('add-user', newUser);
    // }
  }

  public removeUser(newUser:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('user-offline', newUser);
    }
  }


  public showOnline(newUser:any) {
    console.log("ONLINE")
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('user-online', newUser);
    }
  }

  public sendMessageEvent(eventName:any, message:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit(eventName, message);
    }
  }
  public fileUploadEvent(message:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('file-uploaded', message);
    }
  }

  public showOffline(newUser:any) {
    console.log("OFFLINE")

    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('user-offline', newUser);
    }
  }


  public showTyping(newUser:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit('typing', newUser);
    }
  }

  public showUsertyping = () => {
    if (isPlatformBrowser(this.platformId)) {
      return Observable.create((observer:any) => {
        this.socket.on('typing', (data:any) => {
          observer.next(data);
        });
      });
    }
  };
  fetch_history = (user:any) => {
    let headers = new HttpHeaders();
    return this.http.get(URL + 'chat/fetch_history_count?email=' + user.email, {
      headers: headers,
    });
  };
  getchatcount=(data:any)=>{
    
    let headers = new HttpHeaders();
    return this.http.get(URL + 'chat/user/unread-count/all?user_id=' + data.user_id, {
      headers: headers,
    });
  }

  put(body:any, path:any) {
    let headers = new HttpHeaders();
    return this.http.put(URL + path, body, {
      headers: headers,
    });
  }

  get(path:any) {
    return this.http.get(URL + path);
  }

  public user_list = (id: string) => {
    let headers = new HttpHeaders();
    return this.http.get(URL + 'chatUser?id=' + id, {
      headers: headers,
    });
  };
  public deleteUserMessage(data:any) {
    let headers = new HttpHeaders();
    return this.http.post(URL + 'chat/delete/message', data, {
      headers: headers,
    });
  }

  postFile(fileToUpload: File, to:any, from:any, img:any) {

    const endpoint = URL + 'chat/uploadfile';
    const formData: FormData = new FormData();
    formData.append('attachment', fileToUpload, fileToUpload.name);
    formData.append('to', to), formData.append('from', from);
    formData.append('img', img);
    return this.http
      .post(endpoint, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
