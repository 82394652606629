import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { ThemeModule } from './theme/theme.module';



const routes: Routes = [
  {
    path: '',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./theme/theme.module').then(m => m.ThemeModule),
  },
  {
    path: "auth",
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    // canActivate: [ActiveRouteGuard]
  },
  {
    path: "summaryguest",
    loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule)
    
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
