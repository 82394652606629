import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CredentialsService } from "../auth/credentials.service";
import { BehaviorService } from "./behavior.service";
// import { ToastrService } from "ngx-toastr";

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

    constructor(private _bs: BehaviorService,public router: Router, private authenticationService: AuthService, private credentialService:CredentialsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          console.log('error',err)
            if (err.status === 401) {
                
                if (err.error.message && err.error.message!==''){
                  
                  // this.toastr.error(err.error.message,'Error');
                }else if (err.error.error.message && err.error.error.message !== "") {
                    // this.toastr.error(err.error.error.message,'Error');
                  }  
                  this.credentialService.logout();
                  this._bs.unsetUser();
                // this.authenticationService.logout();
                this.router.navigateByUrl('/');
            }else  if (err.status == 400) {
              if (err.error.message && err.error.message!==''){
                // this.toastr.error(err.error.message,'Error');
              }else if (err.error.error.message && err.error.error.message !== "") {
                  // this.toastr.error(err.error.error.message,'Error');
                }  
                return throwError(err.error);
              }else  if (err.status == 404) { 
              //  console.log('error 33',err.error)
                if (err.error.message && err.error.message!==''){
                  // this.toastr.error(err.error.message,'Error');
                } else  if (err.error.error.message && err.error.error.message !== "") {
                  // this.toastr.error(err.error.error.message,'Error');
                } 
                return throwError(err.error);
                
              }
              else  if (err.status == 302) { 
                console.log('error 302',err.error)
                if (err.error.message && err.error.message!==''){
                  // this.toastr.error(err.error.message,'Error');
                } else  if (err.error.error.message && err.error.error.message !== "") {
                  // this.toastr.error(err.error.error.message,'Error');
                } 
                return throwError(err.error);
                
              }
              else if (err.status === 500) {
                if (err.error.message!==''){
                    // this.toastr.error(err.error.message,'Error');
                  }else if (err.error.error.message !== "") {
                      // this.toastr.error(err.error.error.message,'Error');
                    } 
            }
            // const error = err.error.message || err.code;
            return throwError(err);
        }))
    }
}