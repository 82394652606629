import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxNotificationMsgModule } from "ngx-notification-msg";
import { ErrorInterceptor } from "./shared/error.interceptor";
import { AuthInterceptor } from "./shared/auth-interceptor";
import { BehaviorService } from "./shared/behavior.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { DatePipe } from "@angular/common";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
// import { DndModule } from "ngx-drag-drop";
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatSelectModule } from "@angular/material/select";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { ColorPickerModule } from 'ngx-color-picker';
import { ChatService } from './chat.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from "src/environments/environment";

const URL = environment.chat_url;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxNotificationMsgModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    ColorPickerModule,

  

    EditorModule,
    ReactiveFormsModule,
    FormsModule,
    // NgbModule,
    NgxDatatableModule,
    // NgMultiSelectDropDownModule,
    // NgxIntlTelInputModule,
    MatSelectModule,
     OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SocketIoModule.forRoot({
      url: URL,
      options: { transports: ["websocket"], upgrade: false },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    BehaviorService,
    DatePipe,
    ChatService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
