// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://74.208.206.18:4020/',
 // url: "http://74.208.206.18:4111/",
  url: "https://endpoint.daisy-base.com/",
//  quickbookUrl: 'https://sandbox-quickbooks.api.intuit.com/',
quickbookUrl: 'https://quickbooks.api.intuit.com/', 
 chat_url : 'http://74.208.206.18:4115/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const KLIN = {
  Model:'457',
  Fiber:'120',
  Luster:'121',
  FiberBlanket:'455'
};

export const pricing ={
  pattern:'384',
  rondalColor:'385',
  rondelInterstitial:'335',
  rondelInterstitialCost:'118',
  rondalDiameter:'123',
  mosaic_fireboxframe:'456',
  mosaic_thinset:'454',

 rondels_pattern:'452',
 rondels_interstitialglass:'453'
};
