import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BehaviorService {
    subscribe(arg0: (user: any) => any) {
      throw new Error("Method not implemented.");
    }

    public userData: BehaviorSubject<object> = new BehaviorSubject<object>({});
    // public backData: BehaviorSubject<string> = new BehaviorSubject<string>("category");
    public gridView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public updateNotf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public chatTab: BehaviorSubject<string> = new BehaviorSubject<string>('');

    
    public openMdl : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    passData: BehaviorSubject<object> = new BehaviorSubject<object>({});;
    public socketMsg: BehaviorSubject<any> = new BehaviorSubject<any>(false);

    public sidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public sidebarCal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    constructor() { }
public connectemail=new BehaviorSubject<any>(null)

    // setUserData(value) {
    //     let user: object;
    // let userObject = { user: value };
    // console.log(userObject,"fgjkfjkfhjkghjk")
    // this.user.next(userObject);
    // return {};
    // }

    // setUserData(data) {
    //     console.log('user...',data);
    //     this.user.next(data);
    // }
    setUserData(data) {
        this.userData.next(data);
    }

    getUserData() {
        return this.userData.asObservable();
    }

    openModlSet(data) {
        console.log('openMdl', data);
        this.openMdl.next(data);
        return data
    }
    dateToString(p: any) {
        // console.log('logged date',p)
            p=new Date(p)
        // return moment(p, 'yyyy-MM-dd').format('yyyy-MM-DD')
        let month:any=p.getMonth() + 1
        let date:any=p.getDate()
        if(month<10) month=`0${month}`
        if(date<10) date=`0${date}`

        return `${p.getFullYear()}-${month}-${date}`
    }

    // openModlGet() {
    //     return this.openMdl.asObservable();
    // }

    unsetUser() {
        this.userData.next({});
        return {};
    }

    backSubcat(data) {
        this.userData.next(data);
    }
    callBackfuncation(data:any){
        this.passData.next(data)
    }
   
    emailConnected(data: any) {
        this.connectemail.next(data);
      }
      emailConnectedreturn() {
        return this.connectemail.asObservable();
      }


      setSocketData(data: any) {
        if (data) {
            this.socketMsg.next(data);
        }
    }
    getSocketData() {
        return this.socketMsg.asObservable();
  
    }

    setside(data){
        this.sidebar.next(data);
    }

    setside2(){
       return this.sidebar.asObservable();
    }

    setsideCal(data){
        this.sidebarCal.next(data);
    }

    setside2Cal(){
       return this.sidebarCal.asObservable();
    }

    setchatTab(){
        return this.chatTab.asObservable();

    }
    setchatTab2(data){
        this.chatTab.next(data);
    }


    updateNotfi(){
        return this.updateNotf.asObservable();

    }
    updateNotfi2(data:boolean){
        this.updateNotf.next(data);
    }

}
