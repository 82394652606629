import { Component } from "@angular/core";
import { DatePipe } from "@angular/common";
import { HttpEvent, HttpEventType, HttpParams } from "@angular/common/http";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DateTimeAdapter } from "ng-pick-datetime";
import { NgxNotificationMsgService, NgxNotificationStatusMsg, } from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "src/app/shared/shared.service";
import { environment } from "src/environments/environment";
declare const tinymce: any
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "TED-admin";


  constructor(
 
  ) {
    
    // this.Form.reset();
  }
  

}
